import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import banner_eaa from'../Assets/banner_eaa.jpg';
import banner_pw from'../Assets/banner_pw.jpg';
import banner_wpi from '../Assets/banner_wpi.jpg';

function ProductBanners() {
  return (
  <div>
  <section id="about" className="relative py-20 bg-black text-white">
    <div className="container mx-auto px-4">
      <div className="items-center flex flex-wrap">
        <div
          // className="w-full md:w-4/12 ml-auto mr-auto px-4"
          data-aos="fade-right"
        >
          <Carousel>
            <Carousel.Item interval={2500}>
              <a href="https://www.topnotchtraining.com.au">
                <img
                  src={banner_wpi}
                  alt="First slide"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <a href="https://www.topnotchtraining.com.au">
                <img
                  src={banner_eaa}
                  alt="Second slide"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item interval={2500}>
              <a href="https://www.topnotchtraining.com.au">
                <img
                  src={banner_pw}
                  alt="Third slide"
                />
              </a>
            </Carousel.Item>
          </Carousel>
        </div>
       </div>
    </div>
  </section>
  </div>
  );
}

export default ProductBanners;