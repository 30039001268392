import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "tailwindcss/tailwind.css"
import Navigation from './Navigation';
import HomePage from './Home';

function App() {
  return (
    <div>
      <Router>
      <div>
        <Navigation/>
        <div className="container">
          <Route exact path="/" component={HomePage} />
        </div>
      </div>
    </Router>
    </div>
  );
}

export default App;
