import React from 'react';
import AboutPage from '../About';
import TrainersPage from '../Trainers';
import ProductBanners from '../ProductBanners';
import Footer from '../Footer';
import tnt_logo from '../Assets/tntlogo_stretch.png';

function HomePage() {
  return (
  <div>
    <main>
      <div className="w-full ml-auto mr-auto text-center">
        <div>
          <img 
            src={tnt_logo} 
            alt="TNT Logo" 
            className="shadow-lg max-w-full mx-auto"
          />
        </div>
      </div>
      <ProductBanners />
      <AboutPage />
      <section className="pb-20 bg-gray-300 -mt-24">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <i className="fas fa-award"></i>
                  </div>
                  <h6 className="text-xl font-semibold">GOAL SPECIFIC TRAINING</h6>
                  <p className="mt-2 mb-4 text-gray-600">
                    Stay committed to your goals and enjoy your gym training. Guaranteed improvements in just the first month training with us.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                    <i className="fas fa-retweet"></i>
                  </div>
                  <h6 className="text-xl font-semibold">REHABILITATION</h6>
                  <p className="mt-2 mb-4 text-gray-600">
                    Train pain free and improve your mobility by learning how to effectively train.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                    <i className="fas fa-fingerprint"></i>
                  </div>
                  <h6 className="text-xl font-semibold">SPORTS PERFORMANCE</h6>
                  <p className="mt-2 mb-4 text-gray-600">
                    We have clients that have reached Muay Thai kickboxing titles through our strength/power training.
                    We seek to create more champions. You will improve your skills and prevent injuries with our training methods.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                    <i className="fas fa-retweet"></i>
                  </div>
                  <h6 className="text-xl font-semibold">
                    REHABILITATION
                  </h6>
                  <p className="mt-2 mb-4 text-gray-600">
                    Train pain free and improve your mobility by learning how to effectively train.
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex-auto">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                    <i className="fas fa-fingerprint"></i>
                  </div>
                  <h6 className="text-xl font-semibold">
                    SPORTS PERFORMANCE
                  </h6>
                  <p className="mt-2 mb-4 text-gray-600">
                    We have clients that have reached Muay Thai kickboxing titles through our strength/power training.
                    We seek to create more champions. You will improve your skills and prevent injuries with our training methods.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <TrainersPage />
      {/* <!-- Contact Header Section --> */}
      {/* <section className="pb-20 relative block bg-black text-white">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          // style="height: 80px; transform: translateZ(0px)"
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4 lg:pt-24 lg:pb-64 pb-20 pt-20">
          <div className="flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold text-white uppercase">
                Contact Us
              </h2>
              <p className="text-lg leading-relaxed mt-4 mb-4">
                Contact us to ask any questions or talk to
                our trainers or anything else
              </p>
            </div>
          </div>
        </div>
      </section> */}
    </main>
    <Footer />
  </div>
  );
}

export default HomePage;
