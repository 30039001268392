import React from 'react';

function Navigation() {
  return (
    <div>
        {/* <nav
        className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between"
        >
        <div
            className="container px-4 mx-auto flex flex-wrap items-center justify-between"
        >
            <div className="">
                <a className="text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-white"
                    href="https://www.topnotchtraining.com.au"
                >
                    
                </a>
            </div>
        </div>
        </nav> */}
    </div>
  );
}

export default Navigation;
