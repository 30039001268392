import React from 'react';
import bennyCamm from'../Assets/camm.jpg';
import bennySackville from'../Assets/sackville.jpg';
import emMordue from '../Assets/mordue.jpg';

function Trainers() {
  return (
  <section className="pt-20 pb-48">
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap justify-center text-center mb-24">
        <div className="w-full lg:w-6/12 px-4">
          <h2 className="text-4xl font-semibold uppercase">
            Meet Our Trainers
          </h2>
          <p className="text-lg leading-relaxed m-4">
            We pride ourselves on the quality of our trainers. We train all
            ages and all levels of experience. Our main focus is to develop 
            our clients' strength, pain-free and consistently providing 
            productive sessions. Our trainers have years of experience 
            and are passionate about teaching. Enquire now for your free trial.
          </p>
        </div>
      </div>
      {/* <!-- Trainer Card Wrapper --> */}
      <div className="flex flex-wrap">
        {/* <!-- Card 1 --> */}
        <div
          className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
          data-aos="flip-right"
        >
          <div className="px-6">
            <img
              alt="..."
              src={bennyCamm}
              className="shadow-lg rounded max-w-full mx-auto"
              // style="max-width: 250px"
            />
            <div className="pt-6 text-center">
              <h5 className="text-xl font-bold">Benny Camm</h5>
              <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                0490 102 865
              </p>
            </div>
          </div>
        </div>
        { /* <!-- Card 2 --> */}
        <div
          className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
          data-aos="flip-right"
        >
          <div className="px-6">
            <img
              alt="..."
              src={emMordue}
              className="shadow-lg rounded max-w-full mx-auto"
              // style="max-width: 250px"
            />
            <div className="pt-6 text-center">
              <h5 className="text-xl font-bold">Emily Mordue</h5>
              <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                0400 652 175
              </p>
            </div>
          </div>
        </div>
        { /* <!-- Card 3 --> */}
        <div
          className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
          data-aos="flip-right"
        >
          <div className="px-6">
            <img
              alt="..."
              src={bennySackville}
              className="shadow-lg rounded max-w-full mx-auto"
              // style="max-width: 250px"
            />
            <div className="pt-6 text-center">
              <h5 className="text-xl font-bold">Benny Sackville</h5>
              <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                Personal Trainer

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    );
}

export default Trainers;